<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'service-admin' }">Service Admin</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Let&apos;s Encrypt Account Setup</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="setupAccount" onSubmit="return false;" @keyup.enter.native="setupAccount">
                            <TextLink href="https://letsencrypt.org/repository/" target="_blank">Let&apos;s Encrypt Terms of Service</TextLink>
                            <v-checkbox
                                ref="termsInput"
                                v-model="editableTermsOfServiceAgreed"
                                label="Agree to Terms of service"
                                color="purple"
                                required
                                outlined
                                ></v-checkbox>
                            <p v-if="termsOfServiceAgreedOn">
                                The terms of service were accepted on {{ termsOfServiceAgreedOn }}.
                            </p>

                            <p>Let&apos;s Encrypt requires an email to be registered for receiving notifications about API changes and other important issues.</p>
                            <v-text-field
                                ref="emailInput"
                                v-model="editableContactEmail"
                                label="Contact email"
                                :rules="emailRules"
                                validate-on-blur
                                color="purple"
                                required
                                hint="Contact email to register with Lets Encrypt"
                                type="text"
                                outlined
                            >
                            </v-text-field>

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="setupAccount" :disabled="!isAccountFormComplete">
                                <span>Save</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>

                    <v-card tile elevation="4" class="pa-0 mt-8">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Let&apos;s Encrypt Client Setup</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="setupClient" onSubmit="return false;" @keyup.enter.native="setupClient">

                            <p>Let&apos;s Encrypt operates two endpoints: Staging and Production. When you are ready to use the production servers, change this to production.</p>
                            <v-select
                                ref="modeInput"
                                v-model="editableMode"
                                label="Mode"
                                :items="modeChoices"
                                color="purple"
                                required
                                outlined
                                >
                            </v-select>

                            <p v-if="isEnabled">
                                <font-awesome-icon :icon="['fas', 'check']" color="green" fixed-width size="1x"/>
                                Ready to use.
                            </p>
                            <p v-if="!isEnabled">
                                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" color="orange" fixed-width size="1x"/>
                                Setup required.
                            </p>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="setupClient" :disabled="!isClientFormComplete">
                                <span>Save</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidEmail } from '@/sdk/input';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        TextLink,
    },
    data: () => ({
        isViewReady: false,
        isEnabled: null,
        termsOfServiceAgreedOn: null,
        editableMode: null,
        editableTermsOfServiceAgreed: false,
        editableContactEmail: null,
        modeChoices: [
            { text: 'Staging', value: 'staging' },
            { text: 'Production', value: 'production' },
        ],
        emailRules: [
            (v) => !!v || 'Email address is required',
            (v) => !v || isValidEmail(v) || 'Email address is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isAccountFormComplete() {
            return /* typeof this.editableContactEmail === 'string' && */ isValidEmail(this.editableContactEmail) && this.editableTermsOfServiceAgreed === true;
        },
        isClientFormComplete() {
            return this.isAccountFormComplete && typeof this.editableMode === 'string';
        },
    },
    watch: {
    },
    methods: {
        async loadLetsencryptState() {
            try {
                this.$store.commit('loading', { loadLetsencryptState: true });
                const response = await this.$client.main().service.getLetsencryptConfig();
                this.isEnabled = response?.isEnabled ?? false;
                this.editableMode = response.mode;
                this.editableContactEmail = response.email ?? this.user.email;
                if (typeof response.tos_agreed_on === 'number') {
                    this.termsOfServiceAgreedOn = new Date(response.tos_agreed_on).toISOString();
                    this.editableTermsOfServiceAgreed = true;
                } else {
                    this.termsOfServiceAgreedOn = null;
                    this.editableTermsOfServiceAgreed = false;
                }
            } catch (err) {
                console.error('loadLetsencryptState failed', err);
            } finally {
                this.$store.commit('loading', { loadLetsencryptState: false });
                this.isViewReady = true;
            }
        },
        async setupAccount() {
            // TODO: call an API that will create the let's encrypt account private key and register with the let's encrypt service
            try {
                this.$store.commit('loading', { letsencryptSetupAccount: true });
                const edit = {};
                if (this.editableContactEmail) {
                    edit.email = this.editableContactEmail;
                }
                if (this.editableTermsOfServiceAgreed) {
                    edit.tos_agreed = this.editableTermsOfServiceAgreed;
                }
                const response = await this.$client.main().service.setupLetsencryptConfig(edit);
                if (response.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
                }
                // this.isEnabled = response?.isEnabled ?? false;
                // this.editableMode = response.letsencryptMode;
                // this.editableTermsOfServiceAgreed = response.termsOfServiceAgreed;
                // this.editableContactEmail = response.email;
            } catch (err) {
                console.error('setup failed', err);
            } finally {
                this.$store.commit('loading', { letsencryptSetupAccount: false });
                this.isViewReady = true;
            }
        },
        async setupClient() {
            // TODO: call an API that will create the let's encrypt account private key and register with the let's encrypt service
            try {
                this.$store.commit('loading', { letsencryptSetupClient: true });
                const edit = {};
                if (this.editableMode) {
                    edit.mode = this.editableMode;
                }
                const response = await this.$client.main().service.setupLetsencryptConfig(edit);
                if (response.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
                }
                this.isEnabled = response?.isEnabled ?? false;
                // this.editableMode = response.letsencryptMode;
                // this.editableTermsOfServiceAgreed = response.termsOfServiceAgreed;
                // this.editableContactEmail = response.email;
            } catch (err) {
                console.error('setup failed', err);
            } finally {
                this.$store.commit('loading', { letsencryptSetupClient: false });
                this.isViewReady = true;
            }
        },
        cancel() {
            this.$router.go(-1);
        },
    },
    mounted() {
        this.loadLetsencryptState();
    },
};
</script>
